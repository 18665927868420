html,
body {
  width: 100%;
  height: 100%;
}

body {
  font-family: "Source Sans Pro","Helvetica Neue",Helvetica,Arial,sans-serif;
}

.text-vertical-center {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.text-vertical-center h1 {
  margin: 40px 0 0 0;
  padding: 0;
  font-size: 4.5em;
  font-weight: 700;
}

.btn-primary {
  color: #fff!important;
  background-color: #5EAC00;
  border-color: #5EAC00;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  color: #004277!important;
  background-color: white;
}

/* navbar-brand */
.navbar-brand {
  font-size: 30px;
  font-weight: 500;
}

.navbar-nav>li>a {
  font-size: 18px;
}

.nav>li>a:focus, .nav>li>a:hover {
  color: #004277!important;
  background-color: #B6DDEE;
}

.navbar-dark {
  color: #fff;
  background-color: #004277;

  .navbar-toggle {

    .icon-bar {
      background-color: $navbar-inverse-toggle-icon-bar-bg;
    }
  }
}

.navbar-dark a {
  color: #fff!important;
}

a.signup {
  color: #426F8F;
}

a.signup2 {
  margin-left: 10px;
  border: 1px solid #426F8F;
  padding: 7px 10px !important;
  margin-top: 7px;
  font-size: 14px !important;
  background-color: transparent;
}

.bg-primary {
  color: #fff;
  background-color: #004277;
}

.bg-primary a {
  color: #fff;
}

/* Header */
.header {
  display: table;
  position: relative;
  width: 100%;
  height: 100%;
  color: white;

  img {
    height: 40%;
    width: auto;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &:before {
    content: ' ';
    display: table;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 1;
    background-color: #004277;
    background-image: url('/assets/images/bg.png');
    background-repeat: no-repeat;
    background-position: 0;
    -ms-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-size: cover;
  }

}

/* section */
.section {
  padding: 50px 0;

  h2 {
    margin-bottom: 40px;
    color: #004277;
  }

  h3 {
    font-size: 20px;
    color: #004277;
  }

  p {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.4;
  }

  label {
    font-size: 16px;
  }

  .form-control {
    font-size: 16px;
  }

  li {
    font-size: 16px;
    font-weight: 300;
    line-height: 2;
  }

  &.bg-primary {
    h3 {
      color: #fff;
    }

    h2 {
      color: #fff;
    }
  }
}
@media (min-width: 768px) {

  .section {

    h3 {

      font-size: 22px;
    }

    p {
      font-size: 18px;
    }

    label {
      font-size: 18px;
    }

    .form-control {
      font-size: 18px;
    }

    li {
      font-size: 18px;
    }
  }
}

/* About */
.about {

}

/* features */
.features {

  h3 {
    margin-top: 10px;
    color: #004277;
  }

  i {
    font-size: 45px;
    color: #004277;
  }
}

/* tour */
.tour {
  text-align: left;

  i {
    font-size: 35px;
  }

  h3 {
    margin-top: 0px;
  }

  .tour-step {
  }

  .tour-step-index {
    text-align: center;
  }
}

/* modules */
.modules {

  .module {
    text-align: left;

    img {
      float: left;
      margin-right: 20px;
    }
  }
}

/* benefits */
.benefits {

  .nav {

    a {
      font-size: 22px;
    }
  }

  .nav-stacked {
    padding-top: 20px;
  }

  .benefit {
    text-align: left;
  }

  .tab-content {
    height: 250px;
  }
}

/* Footer */
footer {
  padding: 30px 0;
}

/* Contacts */
.contact-form {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  min-width: 300px;
  text-align: left;
  width: 50%;
}

/* Customer */
.customer {
  border: 0px solid #B6DDEE;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 10px 10px 10px;
}

.carousel-control {

  &.left, &.right {
    background-image: none !important;
  }

}

.carousel-inner {

  > .item {
    margin-right: $carousel-control-width;
    margin-left: $carousel-control-width;
  }

}

.carousel-control {

  .fa-chevron-left,
  .fa-chevron-right {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    z-index: 5;
    display: inline-block;
  }

  .fa-chevron-left {
    left: 50%;
    margin-left: -10px;
  }
  .fa-chevron-right {
    right: 50%;
    margin-right: -10px;
  }

}

.carousel-indicators {
  bottom: -30px;
}